// slices/postsSlice.js
import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  posts: [],
};

const postsSlice = createSlice({
  name: 'posts',
  initialState,
  reducers: {
    setPosts: (state, action) => {
      state.posts = action.payload;
      console.log(state);
      console.log(action);
    },
    updatePostBackground: (state, action) => {
      const { id, backgroundColor } = action.payload;
      const post = state.posts.find(post => post.id === id);
      if (post) {
        post.backgroundColor = backgroundColor;
      }
    },
  },
});

export const { setPosts, updatePostBackground } = postsSlice.actions;
export default postsSlice.reducer;