import Link from 'next/link';
import React from 'react';

const Custom500 = () => {
  return (
    <>
      <h1>505</h1>
      <div className="flex h-[calc(100vh-4rem)] flex-col items-center justify-center space-y-4 text-center">
        <div className="mb-10"/>
        <h1 className="text-3xl font-bold">Looks like something went wrong!</h1>
        <Link href="/"/>
      </div>
    </>
  );
};

export default Custom500;
