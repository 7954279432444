// chatMessagesSlice.ts
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export interface Message {
  id: string;
  content: string;
  gif_url: string;
  username: string;
  time: string;
}

interface ChatMessagesState {
  messages: Message[];
  loading: boolean;
  error: string | null;
}

const initialState: ChatMessagesState = {
  messages: [],
  loading: false,
  error: null
};

const MessagesSlice = createSlice({
  name: 'chatMessages',
  initialState,
  reducers: {
    fetchMessagesStart(state) {
      state.loading = true;
      state.error = null;
    },
    fetchMessagesSuccess(state, action: PayloadAction<Message[]>) {
      state.loading = false;
      state.error = null;
      state.messages = action.payload;
    },
    fetchMessagesFailure(state, action: PayloadAction<string>) {
      state.loading = false;
      state.error = action.payload;
    },
    sendMessage(state, action: PayloadAction<Message>) {
      state.messages.push(action.payload);
    },
    deleteMessage(state, action: PayloadAction<string>) {
      state.messages = state.messages.filter(
        (message) => message.id !== action.payload
      );
    },
    editMessage(state, action: PayloadAction<Message>) {
      const index = state.messages.findIndex(
        (message) => message.id === action.payload.id
      );
      if (index !== -1) {
        state.messages[index] = action.payload;
      }
    }
  }
});

export const {
  fetchMessagesStart,
  fetchMessagesSuccess,
  fetchMessagesFailure,
  sendMessage,
  deleteMessage,
  editMessage
} = MessagesSlice.actions;

export default MessagesSlice.reducer;
